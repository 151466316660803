import React, { useState } from "react"
import { Container } from "semantic-ui-react"
import workCategories from "../constants/workCategories.json"
import work from "../constants/work.json"
import VideoModal from "../components/VideoModal"
import styles from "./work.module.scss"
import { Helmet } from "react-helmet"
import ReactSoundCloud from "react-soundcloud-embedded"

import itMessages from "../i18n/it.json"
import enMessages from "../i18n/en.json"

const messages = {
  it: itMessages,
  en: enMessages,
}

function Work(props) {
  const { locale } = props.pageContext

  const [categoryFilter, setCategoryFilter] = useState(null)
  const [selectedWork, setSelectedWork] = useState(null)

  function renderFilters() {
    return workCategories.map((category, i) => {
      return (
        <button
          key={i}
          className={`${styles.categoryFilter} ${
            categoryFilter === category ? styles.selected : ""
          }`}
          onClick={() => setCategoryFilter(category)}
        >
          {category}
        </button>
      )
    })
  }

  function renderWorkItems() {
    const filteredWork = categoryFilter
      ? work.filter(item => item.category === categoryFilter)
      : work

    return filteredWork.map((workItem, i) => {
      return (
        <div
          key={i}
          className={styles.workItem}
          onClick={() => setSelectedWork(workItem)}
        >
          <div className={styles.workItemShade}>
            <span className={styles.workItemName}>{workItem.name}</span>
          </div>
          <img src={workItem.coverImg} />
        </div>
      )
    })
  }

  return (
    <>
      <Helmet>
        <title>
          {locale === "en" ? "Work - Amplitudo" : "Lavori - Amplitudo"}
        </title>
        <meta
          name="description"
          content={
            locale === "en"
              ? "We work on videos, radio adverts, TV adverts, podcasts, films, videogames, museum installation, theatre, art exhibitions, live sound events, educational, workshops."
              : "Lavoriamo con video, pubblicità radio, pubblicità TV, podcast, film, videogiochi, installazioni sonore, musei, gallerie, set, shooting, istruzione e workshops."
          }
        />
      </Helmet>
      <div>
        <div className="centered-title-container">
          <div>
            <h1 className="centered-title">Work</h1>
            <div className="centered-title-background"></div>
          </div>
        </div>
        {/* <div className={styles.categoryFiltersContainer}>
          <>
            <button
              onClick={() => setCategoryFilter(null)}
              className={`${styles.categoryFilter} ${
                categoryFilter === null ? styles.selected : ""
              }`}
            >
              {messages[locale].all}
            </button>
            {renderFilters()}
          </>
        </div> */}
        <Container>
          <div className={styles.workGrid}>{renderWorkItems()}</div>
          <div className={styles.soundCloudRow}>
            <div className={styles.soundCloudCol}>
              <h2 className="centered-title section-title-services">
                {messages[locale].radioAds}
              </h2>
              <div>
                {/* <ReactSoundCloud
                  url={
                    "https://soundcloud.com/amplitudoagency/sets/spot-radio?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                  }
                /> */}
                <>
                  <iframe
                    width="100%"
                    height={450}
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1204933738&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                  />
                  <div
                    style={{
                      fontSize: 10,
                      color: "#cccccc",
                      lineBreak: "anywhere",
                      wordBreak: "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    <a
                      href="https://soundcloud.com/amplitudoagency"
                      title="Amplitudo"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      Amplitudo
                    </a>{" "}
                    ·{" "}
                    <a
                      href="https://soundcloud.com/amplitudoagency/sets/spot-radio"
                      title="Spot Radio"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      Spot Radio
                    </a>
                  </div>
                </>
              </div>
            </div>
            <div className={styles.soundCloudCol}>
              <h2 className="centered-title section-title-services">
                {messages[locale].podcasts}
              </h2>
              <div>
                {/* <ReactSoundCloud
                  url={
                    "https://soundcloud.com/amplitudoagency/sets/podcast?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                  }
                /> */}
                <>
                  <iframe
                    width="100%"
                    height={450}
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1504173709&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                  />
                  <div
                    style={{
                      fontSize: 10,
                      color: "#cccccc",
                      lineBreak: "anywhere",
                      wordBreak: "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    <a
                      href="https://soundcloud.com/amplitudoagency"
                      title="Amplitudo"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      Amplitudo
                    </a>{" "}
                    ·{" "}
                    <a
                      href="https://soundcloud.com/amplitudoagency/sets/podcast"
                      title="Podcast"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      Podcast
                    </a>
                  </div>
                </>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <VideoModal
        selectedWork={selectedWork}
        setSelectedWork={setSelectedWork}
      />
    </>
  )
}

export default Work
